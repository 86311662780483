import "./style.css";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as dat from "dat.gui";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { TextureLoader } from "three/src/loaders/TextureLoader.js";

import tap01 from "../static/img/ui/rolex cosmograph daytona-01.png";
import tap02 from "../static/img/ui/rolex cosmograph daytona-04.png";
import tap03 from "../static/img/ui/rolex cosmograph daytona-05.png";
import tap04 from "../static/img/ui/rolex cosmograph daytona-08.png";

import catgdial01 from "../static/img/ui/rendus/rolex patern-gold.png";
import catgdial02 from "../static/img/ui/rendus/rolex patern-rose-gold.png";
import catgdial03 from "../static/img/ui/rendus/rolex patern-silver.png";
import catgdial04 from "../static/img/ui/rendus/rolex patern-orange-gold.png";

import catgcase01 from "../static/img/ui/rendus/ring-gold.png";
import catgcase02 from "../static/img/ui/rendus/ring-rose-gold.png";
import catgcase03 from "../static/img/ui/rendus/ring-silver.png";
import catgcase04 from "../static/img/ui/rendus/ring-orange-gold.png";

import catghands01 from "../static/img/ui/rendus/aiguilles-gold.png";
import catghands02 from "../static/img/ui/rendus/aiguilles-rose-gold.png";
import catghands03 from "../static/img/ui/rendus/aiguilles-silver.png";
import catghands04 from "../static/img/ui/rendus/aiguilles-orange-gold.png";

import catgstrap01 from "../static/img/ui/rendus/Braclet-gold.png";
import catgstrap02 from "../static/img/ui/rendus/Braclet-rose-gold.png";
import catgstrap03 from "../static/img/ui/rendus/Braclet-silver.png";
import catgstrap04 from "../static/img/ui/rendus/Braclet-orange-gold.png";

import rolex_logo from "../static/img/Rolex_logo.png";

import mouse_01 from "../static/img/mouseleft.png";
import mouse_02 from "../static/img/mouseright.png";
import mouse_03 from "../static/img/mousescroll.png";

import info_btn from "../static/img/172483_info_icon.png";

import { sRGBEncoding } from "three";

//charger image
chargerImage();

// Debug
//const gui = new dat.GUI();
const debugObject = {};

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();
scene.background = new THREE.Color(0xffffff);

// Objects
const geometry = new THREE.TorusGeometry(0.7, 0.2, 16, 100);

// Materials

const material = new THREE.MeshBasicMaterial();
material.color = new THREE.Color(0xff0000);

// Mesh
//const sphere = new THREE.Mesh(geometry, material);
//scene.add(sphere);

// Lights
const hemesphireLight = new THREE.HemisphereLight(0xffffff, 1);
scene.add(hemesphireLight);
/* const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
directionalLight.position.x = 10;
directionalLight.position.y = 10;
directionalLight.position.z = 0;
directionalLight.castShadow = true;
//directionalLight.shadow.camera.far = 15;
directionalLight.shadow.camera.near = 0.5; // default
directionalLight.shadow.camera.far = 500; // defaul
directionalLight.shadow.mapSize.set(1024, 1024);
directionalLight.shadow.normalBias = 0.05;
scene.add(directionalLight);

//Create a helper for the shadow camera (optional)
const helper = new THREE.CameraHelper(directionalLight.shadow.camera);
scene.add(helper);

const directionalLight1 = new THREE.DirectionalLight(0xffffff, 1);
directionalLight1.position.x = 3;
directionalLight1.position.y = 3;
directionalLight1.position.z = -3;
directionalLight1.castShadow = true;
directionalLight1.shadow.camera.far = 15;
directionalLight1.shadow.mapSize.set(1024, 1024);
directionalLight1.shadow.normalBias = 0.05;
scene.add(directionalLight1);

const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1);
directionalLight2.position.x = 3;
directionalLight2.position.y = 3;
directionalLight2.position.z = 3;
directionalLight2.castShadow = true;
directionalLight2.shadow.camera.far = 15;
directionalLight2.shadow.mapSize.set(1024, 1024);
directionalLight2.shadow.normalBias = 0.05;
scene.add(directionalLight2);

const directionalLight3 = new THREE.DirectionalLight(0xffffff, 1);
directionalLight3.position.x = 3;
directionalLight3.position.y = 3;
directionalLight3.position.z = -3;
directionalLight3.castShadow = true;
directionalLight3.shadow.camera.far = 15;
directionalLight3.shadow.mapSize.set(1024, 1024);
directionalLight3.shadow.normalBias = 0.05;
scene.add(directionalLight3);

const directionLightcamerahelper = new THREE.CameraHelper(
  directionalLight.shadow.camera
);
scene.add(directionLightcamerahelper); */

const pointLight = new THREE.AmbientLight(0xffffff, 2);
pointLight.position.x = 3;
pointLight.position.y = 0;
pointLight.position.z = 0;
//pointLight.castShadow = true;
//pointLight.shadow.camera.far = 15;
//pointLight.shadow.mapSize.set(1024, 1024);
//pointLight.shadow.normalBias = 0.05;
scene.add(pointLight);

/**
 * Sizes
 */
var sizes = {};
if (window.innerWidth <= 899) {
  sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
} else {
  sizes = {
    width: (3 * window.innerWidth) / 4,
    height: window.innerHeight,
  };
}

window.addEventListener("resize", () => {
  // Update sizes
  if (window.innerWidth <= 899) {
    sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  } else {
    sizes = {
      width: (3 * window.innerWidth) / 4,
      height: window.innerHeight,
    };
  }

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);
if (window.innerWidth <= 899) {
  camera.position.x = 8;
  camera.position.y = 0.6;
} else {
  camera.position.x = 6;
}

//camera.position.y = 6;
//camera.position.z = 6;

//camera.rotateZ(Math.PI * 0.25);

scene.add(camera);

//environment Map
const cubeTextureLoader = new THREE.CubeTextureLoader();
const enviromentmap = cubeTextureLoader.load([
  "textures/environmentMap/px.png",
  "textures/environmentMap/nx.png",
  "textures/environmentMap/py.png",
  "textures/environmentMap/ny.png",
  "textures/environmentMap/pz.png",
  "textures/environmentMap/nz.png",
]);
enviromentmap.encoding = sRGBEncoding;
scene.enviromentmap = enviromentmap;
//scene.background = enviromentmap;
debugObject.envMapIntensity = 5;
/* gui
  .add(debugObject, "envMapIntensity")
  .min(0)
  .max(10)
  .step(0.001)
  .onChange(() => {
    UpdateAllMaterials();
  }); */

//create plane
/* const planegeometry = new THREE.PlaneGeometry(100, 100);
const planematerial = new THREE.MeshBasicMaterial({
  color: 0xff00ff,
  side: THREE.DoubleSide,
});
const plane = new THREE.Mesh(planegeometry, planematerial);
plane.position.set(0, -2.4, 0);
plane.rotation.x = Math.PI * 0.5;
plane.receiveShadow = true;
scene.add(plane); */

//create object
loadObject("src");

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.physicallyCorrectLights = true;
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.toneMapping = THREE.LinearToneMapping;
renderer.toneMappingExposure = 0.8;
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;

/* gui
  .add(renderer, "toneMapping", {
    No: THREE.NoToneMapping,
    Liner: THREE.LinearToneMapping,
    Reinhand: THREE.ReinhardToneMapping,
    Cineon: THREE.CineonToneMapping,
    ACESFilmic: THREE.ACESFilmicToneMapping,
  })
  .onFinishChange(() => {
    renderer.toneMapping = Number(renderer.toneMapping);
    UpdateAllMaterials();
  });

gui.add(renderer, "toneMappingExposure").min(0).max(10).step(0.001); */
/**
 * Animate
 */

const clock = new THREE.Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  // Update objects
  //sphere.rotation.y = 0.5 * elapsedTime;

  // Update Orbital Controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();

function chargerImage() {
  document.getElementById("tap01").src = tap01;
  document.getElementById("tap02").src = tap02;
  document.getElementById("tap03").src = tap03;
  document.getElementById("tap04").src = tap04;

  document.getElementById("catg-dial-01").src = catgdial01;
  document.getElementById("catg-dial-02").src = catgdial02;
  document.getElementById("catg-dial-03").src = catgdial03;
  document.getElementById("catg-dial-04").src = catgdial04;

  document.getElementById("catg-case-01").src = catgcase01;
  document.getElementById("catg-case-02").src = catgcase02;
  document.getElementById("catg-case-03").src = catgcase03;
  document.getElementById("catg-case-04").src = catgcase04;

  document.getElementById("catg-hands-01").src = catghands01;
  document.getElementById("catg-hands-02").src = catghands02;
  document.getElementById("catg-hands-03").src = catghands03;
  document.getElementById("catg-hands-04").src = catghands04;

  document.getElementById("catg-strap-01").src = catgstrap01;
  document.getElementById("catg-strap-02").src = catgstrap02;
  document.getElementById("catg-strap-03").src = catgstrap03;
  document.getElementById("catg-strap-04").src = catgstrap04;

  document.getElementById("rolex_logo").src = rolex_logo;

  document.getElementById("mouse_01").src = mouse_01;
  document.getElementById("mouse_02").src = mouse_02;
  document.getElementById("mouse_03").src = mouse_03;

  document.getElementById("info_btn").src = info_btn;
}

function loadObject(src) {
  const loadingManager = new THREE.LoadingManager(() => {
    /* loader */
    const loadingScreen = document.getElementById("loading-screen");
    loadingScreen.classList.add("fade-out");

    // optional: remove loader from DOM via event listener
    loadingScreen.addEventListener("transitionend", onTransitionEnd);
    /* loader */

    scene.getObjectByName("WATCH_ring").material.color = new THREE.Color(
      "#d1a442"
    );
    const objectBtn = [
      { btn: "dial", obj: "WATCH_timer" },
      { btn: "dial", obj: "WATCH_chrono_Cylinder" },
      { btn: "case", obj: "WATCH_ring" },
      { btn: "hands", obj: "WATCH_polySurface154_group11" },
      { btn: "hands", obj: "WATCH_chrono_aiguilles" },
      { btn: "strap", obj: "WATCH_Branceletbrushed" },
      { btn: "strap", obj: "WATCH_Branceletmate" },
      { btn: "strap", obj: "WATCH_buttons" },
    ];
    const colorarry = ["#d1a442", "#cb8368", "#949494", "#c6633f"];
    objectBtn.forEach(function (arrayItem) {
      var object = scene.getObjectByName(arrayItem.obj);
      for (let i = 1; i < 5; i++) {
        document
          .getElementById("catg-" + arrayItem.btn + "-0" + i)
          .addEventListener("click", function () {
            changeColor(object, colorarry[i - 1]);
          });
      }
    });
    /*     scene.traverse((child) => {
      console.log(child.name);
  
      if (child.name === "WATCH_Branceletbrushed") {
        console.log("hy");
        document
          .getElementById("catg-strap-01")
          .addEventListener("click", function () {
            changeColor(child, "#d1a442");
          });
        document
          .getElementById("catg-strap-02")
          .addEventListener("click", function () {
            changeColor(child, "#cb8368");
          });
        document
          .getElementById("catg-strap-03")
          .addEventListener("click", function () {
            changeColor(child, "#949494");
          });
        document
          .getElementById("catg-strap-04")
          .addEventListener("click", function () {
            changeColor(child, "#c6633f");
          });
      }
    }); */
  });
  const gltfloader = new GLTFLoader(loadingManager);
  gltfloader.load("models/relex/rolex watch _v02.glb", (gltf) => {
    gltf.scene.scale.set(1, 1, 1);
    if (window.innerWidth <= 899) {
      gltf.scene.position.set(0, 0.6, 0);
      //gltf.scene.rotation.z = -(Math.PI * 1) / 24;
    } else {
      gltf.scene.position.set(0, 0.05, 0);
      gltf.scene.rotation.y = -(Math.PI * 1) / 8;
    }

    camera.lookAt(gltf.scene);
    scene.add(gltf.scene);
    UpdateAllMaterials();
  });
}

const UpdateAllMaterials = () => {
  //console.log("hello");
  scene.traverse((child) => {
    if (
      child instanceof THREE.Mesh &&
      child.material instanceof THREE.MeshStandardMaterial
    ) {
      //console.log(child);
      child.material.envMap = enviromentmap;
      child.material.envMapIntensity = debugObject.envMapIntensity;
      child.material.needsUpdate = true;
      child.castShadow = true;
      child.receiveShadow = true;
    }
  });
};

function createMaterial() {
  // create a texture loader.
  const textureLoader = new TextureLoader();

  // load a texture
  const texture = textureLoader.load("textures/rolex wihte patern.jpg");

  // create a "standard" material using
  // the texture we just loaded as a color map
  const material = new THREE.MeshStandardMaterial({
    map: texture,
  });

  return material;
}

function changeColor(chil, col) {
  chil.material.color = new THREE.Color(col);
  //UpdateAllMaterials();
}

function onTransitionEnd(event) {
  event.target.remove();
}
